@import "../../variables/index";
$color: white;
.service {
  width: 680px;
  &-wrapper {
    margin: 20px 0;
    &__btn {
      width: 100%;
      padding: 10px;
      background: $colorDesign;
      outline: none;
      border: none;
      border-radius: 15px;
      color: #FFFFFF;
      cursor: pointer;
    }
    &__btn--cancel {
      margin-top: 20px;
      width: 100%;
      padding: 10px;
      background: #FFFFFF;
      outline: none;
      border: 1px solid $colorDesign;
      border-radius: 15px;
      color: $colorDesign;
    }
  }

  &_title {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
  }

  &_content {
    font-weight: 400;
    margin-bottom: 30px;
    overflow: hidden;
  }

  &_fields {
    margin-bottom: 45px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &_fields-item {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    &--mini-margin {
      margin-bottom: 10px;
    }
  }

  &_phone-field {
    display: flex;
  }

  &_description-field,
  &_debt-additional-field-items {
    background: #fff7e4;
    border-radius: 5px;
    padding: 15px;
  }

  &_description-field {
    line-height: 17px;
    font-weight: 500;
    border-radius: 15px;
    &-title {
      margin-bottom: 10px;
    }
  }

  &_debt-additional-field-item {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    &-title {
      margin-bottom: 4px;
      font-weight: 700;
    }
    &-body {
      font-weight: 500;
    }
  }

  &__pay-another-card {
    display: flex;
    align-items: center;
  }

  &__card-box-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $gray-color;
    margin-right: 25px;
  }

  &__another-card-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $orange-color;
    margin-left: 7px;
    cursor: pointer;
  }

  &__another-card-box {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__another-card-icon {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid $orange-color;
    color: $orange-color;
  }

  &_phone-placeholder {
    padding: 7px 17px 5px 0;
    border-bottom: 1px solid $colorDesign;
  }

  &_error {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vw;
    height: 100vh;
    margin: 0 auto;
    background: red;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
  }

  button:disabled,
  button[disabled]{
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    width: 100%;
    padding: 10px;
  }

  input,
  select {
    border: none;
    border-bottom: 1px solid $colorDesign;
    outline: none;
    padding: 5px 30px 5px 0;
  }

  input {
    width: 100%;
  }

  select {
    margin-left: -3px;
  }
}

.chech-wrapper {
  display: flex;
  width: 1140px;
  margin: 0 auto;
  justify-content: space-between;

  .container {
    margin: 0;
    padding-left: 0;
  }

  .layout__app-otherWrap {
    margin-top: 0;
    width: 100px;
  }
}

.chech-wrapper-other-panel {
  width: 33%;
  margin-bottom: 30px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  overflow: hidden;
  padding: 24px;
  margin-top: 30px;
}

.check_result-receipt {
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  margin: 14px 0;
  cursor: pointer;
  width: 100%;
}

.check-wrapper-head {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.check-head {
  display: flex;
  justify-content: space-between;
  padding: 24px 16px;
  height: 80px;
  background: rgba(239, 92, 39, 1);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  &__left {
    display: flex;
    align-items: center;
    width: 50%;
    font-size: 22px;
    color: white;
    img {
      margin-right: 3px;
    }
  }
  &__right {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 50%;
    font-size: 16px;
    color: white;
    img {
      margin-right: 3px;
    }
    button {
      cursor: pointer;
      color: white;
      background: rgb(239, 92, 39);
      border: none;
      outline: none;

      &:hover {
        background: rgb(239, 92, 39);
      }
    }
  }
  &__bonus {
    display: flex;
    justify-content: space-between;
    background: rgba(255, 245, 241, 1);
    border: 1px solid rgba(255, 245, 241, 1);
  }
}

.check-other-paymnet {
  display: flex
}
.check-other-paymnet-swiper {
  display: none;
}

.solid {
  background: $color;
}

.box {
  padding: 10px;
}

.zag-out:after {
  top: 100%;
  background:
    linear-gradient(135deg, $color 33.333%, transparent 0),
    linear-gradient(-135deg, $color 33.333%, transparent 0);
}

.zig {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 0.5em;
    background-size: 1em 100%;
    background-position: center;
  }
}

.shadow {
  -webkit-filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 1px 1px);
}

.check_data-items {
  background: url('../../img/stamp.png');
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: clamp(20%, 200px, 30%) auto;
}

.check_data-item {
  gap: 15px;
}

.check_data-item > div {
  flex: 1;
}

.check_data-item-sum {
  border-top: 2px solid rgba(217, 217, 217, 1);
  margin: 10px auto;
  width: 91%;
}

.check .modal__content.active {
  width: 95%;
  max-width: 500px;
}

.check .receipt {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__close-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
    align-self: flex-end;
  }

  &__content {
    width: 90%;
    margin: 30px auto 30px auto;
  }

  &__field {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 49px;
  }

  &__field-label {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.7);
  }

  &__field-input {
    width: 100%;
    padding: 0 35px 0 0;
    font-size: 16px;

  }

  &__message-icon {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 0;
    bottom: 7px;
  }

  &__error {
    color: red;
    position: absolute;
    bottom: -31px;
    font-size: 12px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__btns {
    display: flex;
    flex-direction: column;
  }

  &__btn {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    padding: 20px 0;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
  }

  &__btn--send {
    background-color: $orange-color;
    color: $white-color;
  }

  &__btn--print {
    color:  $orange-color;
  }
}

.container {
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 580px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  &--not-right-column{
    margin-bottom: 40px;
    margin-top: 20px;
  }
  &--check {
    .service {
      margin-right: 0 !important;
    }
  }
}

.check__btns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 40px 0;

  .custom-button {
    padding: 14px !important;
    text-align: center !important;
    width: 100% !important;
  }
}
.check__link {
  text-decoration: none;
  width: 49%;
}

@media (max-width: 1140px) {
  .chech-wrapper {
    width: 95%;
    margin: 0 auto;
  }
}

@media (max-width: 900px) {
  .chech-wrapper-other-panel {
    display: none
  }
  .check-other-paymnet-swiper {
    display: flex;
  }

  .check-wrapper-head {
    margin-top: 10px;
  }
}

.check-data-content {
  padding: 0 10px;
  @media (max-width: 600px) {
    padding: 0;
  }
}

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
.spin::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 3px #cfd0d1;
  border-bottom-color: #e74c3c;
  border-radius: 50%;
  content: "";
  height: 15px;
  width: 15px;
  position: absolute;
  top: 8px;
  left: 44%;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}

@media (max-width: 600px) {
  .service_description-field {
    font-size: 14px;
  }
}

@media (max-width: 380px) {
  .check__link {
    width: 100%;
    margin: 7px 0;
  }

  .check__btns {
    margin-top: -7px;
  }

  .check_result-receipt {
    margin: 7px 0 14px 0;
  }

  .check_data-item {
    flex-wrap: wrap;
  }

  .check_data-item-title--title {
    color: $black-color;
    margin-bottom: 5px;
  }

  .check__btns {
    .custom-button {
      font-size: 13px !important;
    }
  }
}

.bonus-text {
  display: flex;
  flex-wrap: wrap;
}
.spaces {
  margin-left: 5px;
}
.bonus-amount {
  margin-right: 5px;
}
.bonus-auth {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 14px 30px;
}