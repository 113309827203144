@import "../../variables/index";

.history {
  max-width:$width;
  margin: 0 auto;
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-grow: 1;
  .react-datepicker-wrapper {
    width: 245px;
  }
  .react-datepicker__input-container input {
    width: 240px;
  }
  .react-datepicker__close-icon {
    left: 215px;
    top: -7px;
    padding: 0;
    &::after {
      background-color: #e74c3c;
    }
  }
  &__30days {
    color: #474747;
    text-align: center;
    padding-bottom: 25px;
  }

  &__tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    gap: 15px;
    .history-picker {
      @media (max-width: 495px) {
        margin: auto;
      }
    }
    .tabs {
      display: flex;
      gap: 15px;
      @media (max-width: 495px) {
        width: 100%;
      }
      > * {
        @media (max-width: 495px) {
          flex: 1;
          padding: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    @media (max-width: 905px) {
      flex-direction: column;
      align-items: flex-start
    }
    @media (max-width: 900px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    @media (max-width: 585px) {
      flex-direction: column;
      align-items: flex-start
    }
  }

  &__tab {
    border-radius: 15px;
    width: fit-content;
    padding: 14px;
    text-align: center;
    background-color: #a3a3a357;
    cursor: pointer;
    color: $black-color;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }

  &__tab--active {
    background-color: $orange-color;
    color: $white-color;
  }

  &-left {
    //min-width: 753px;
    //max-width: 753px;
    width: 100%;

    @media (max-width: 900px) {
      width: 100%;

    }

    &-date {
      margin-bottom: 15px;
    }

    &__title {
      font-size: 26px;
      font-weight: 600;
      align-self: center;

      @media (max-width: 768px) {
        font-size: 20px;
        margin-bottom: 10px;
      }
      @media (max-width: 500px) {
        font-size: 20px;
        margin-bottom: 10px;
        width: 100%;
        float: left;
      }

      &-wrapper {
        padding: 15px 0;
        display: flex;
        justify-content: space-between;

        @media (max-width: 500px) {
          display: flex;
          flex-direction: column;
          //padding: 15px 10px 10px;
        }
      }

    }

    &__wrap {
      display: flex;
      height: 52px;
      background-color: $white-color-border;
      border-radius: 15px;

      @media (max-width: 500px) {
        height: 40px;
      }

      &-input {
        width: 366px;
        height: 40px;
        display: flex;
        align-self: center;
        outline: none;
        border: none;
        padding: 10px;
        border-radius: 10px;
        background-color: $white-color-border;

        @media (max-width: 500px) {
          width: 100%;
          //background: res;
        }
      }

      &-button {
        display: flex;
        align-self: center;
      }
    }

    &__body {
      margin-bottom: 20px;
      width: 100%;
      //border: 1px solid red;

      //.css-1n3tkec {
      //  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
      //  border-radius: 15px;
      //}

      .css-1n3tkec.Mui-expanded {
        width: 100% !important;;
        border-radius: 15px !important;
        box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px !important;
      }

      .css-1iji0d4 {
        padding: 0 !important;
      }

      &-time {
        padding-right: 20px;
        //font-size: 16px;
        //font-weight: 600;
        //color: $black-color;
      }

      &-result {
        padding-right: 20px;

        @media (max-width: 1000px) {
          display: none;
        }
      }

      &-empty {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        align-items: center;
        display: flex;
        color: $gray-color;
        padding-top: 15px;
      }

      &-logo {
        padding-right: 10px;
        width: 60px;
      }

      &-amount {
        display: flex;
        align-items: end;
        //min-width: 120px;

        //border: 1px solid red;
        font-size: 16px;
        //font-weight: 600;
        margin-left: auto;

        &__currency {
          font-size: 14px;
          padding-left: 3px;
        }
      }

      &-name {
        font-size: 14px;

        &__block {
          //min-width: 257px;
          width: 35%;
          //border: 1px solid red;
        }
      }

      &-result {
        padding-left: 20px;
        font-size: 12px;
        text-align: center;
        width: 15%;
        //border: 1px solid red;
      }

      &-time {
        min-width: 40px;
      }

      &-item {
        display: flex;
        align-items: center;
        padding: 10px;
        //border-bottom: 1px solid $rgba-white-color;
        width: 100%;

        @media (max-width: 1000px) {
          justify-content: space-between;
          padding-left: 10px;
        }

        @media (max-width: 660px) {
          justify-content: start;
        }
      }
    }


    .secondStyles {
      width: 33%;
      display: flex;

      .custom-button {
        width: 100%;
      }


      @media (max-width: 1100px) {
        display: flex;
      }

      @media (max-width: 768px) {
        display: flex;
      }

      @media (max-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 10px;
      }

    }

    @media (max-width: 768px) {
      .secondStyles {
        .custom-button {
          @media (max-width: 768px) {
            display: flex;
            font-size: 10px;
            padding: 5px 10px !important;
          }
        }
      }
    }

    @media (max-width: 1100px) {
      .secondStyles {
        .custom-button {
          @media (max-width: 1100px) {
            display: flex;
            font-size: 10px;
            padding: 5px 10px !important;
          }
        }
      }
    }

    @media (max-width: 500px) {
      .secondStyles {
        .custom-button {
          @media (max-width: 500px) {
            display: flex;
            font-size: 10px;
            padding: 5px 10px !important;
          }
        }
      }
    }


    .css-o4b71y-MuiAccordionSummary-content {
      display: block;
      margin: 0;
      width: 95%;
      //border: 1px solid black;
    }

    .css-yw020d-MuiAccordionSummary-expandIconWrapper {
      width: 5%;
      margin-right: 10px;
    }

    .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
      margin: 0;
    }

    .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
      border-radius: 15px 15px;
    }
  }

  &-right {
    //max-width: 367px;
    margin-top: 20px;
    width: 32%;

    @media (max-width: 900px) {
      display: none;
    }
  }

  &-control {
    &__buttons {
      display: flex;
      width: 100%;
      justify-content: space-between;

      @media only screen and (max-width: 1100px) {
        width: 80%;
        display: flex;
        margin: 0 auto;
      }

      @media only screen and (max-width: 500px) {
        width: 100%;
        display: flex;
      }

      &-btn {
        display: flex;
      }

      @media only screen and (max-width: 535px) {
        display: flex !important;
        flex-direction: column !important;
      }

      @media only screen and (max-width: 768px) {
        display: flex;
        flex-direction: unset;
      }

      @media only screen and (max-width: 667px) {
        display: flex;
      }
    }

    &__link {
      text-decoration: none;
    }
  }

  .receipt-modal {
    //padding: 15px 19px;
    width: 100%;
    min-height: 356px;
    background: #FFFFFF;
    //box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &__form {
      margin: 75px auto 0 auto;
      width: 100%;
    }

    &__input-box {
      display: flex;
      width: 100%;
      margin-bottom: 49px;
      flex-wrap: wrap;
    }

    &__input-label {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: rgba(0, 0, 0, 0.7);
      margin-bottom: 3px;
    }

    &__icon {
      cursor: pointer;
    }

    &__input {
      width: 100%;
      padding: 0 35px 0 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;

      ::placeholder {
        color: #A6A6A6;
      }
    }

    &__message-icon {
      margin-left: -30px;
    }

    &__footer {
      width: 100%;
    }

    &__send-btn,
    &__print-btn {
      width: 100%;
      text-align: center;
      font-weight: 700;
      border: none;
      outline: none;
    }

    &__send-btn {
      background: #EF5C27;
      border-radius: 10px;
      height: 60px;
      font-size: 16px;
      line-height: 19px;
      color: #FFFFFF;
      margin-bottom: 30px;
      padding: 20px 0;
    }

    &__print-btn {
      background: transparent;
      font-size: 14px;
      line-height: 17px;
      color: #EF5C27;
      cursor: pointer;
    }

    &__error {
      color: red;
      font-size: 14px;
      margin-top: 3px;
    }
  }

  input[type="search"] {
    border: none;
    background: transparent;
    margin: 0;
    padding: 15px;
    font-size: 14px;
    color: inherit;
    border: 1px solid transparent;
    border-radius: inherit;
  }

  input[type="search"]::placeholder {
    color: #bbb;
  }

  button[type="submit"] {
    text-indent: -999px;
    overflow: hidden;
    width: 40px;
    padding: 0;
    margin: 0;
    border: 1px solid transparent;
    border-radius: inherit;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
    cursor: pointer;
    opacity: 0.7;
  }

  button[type="submit"]:hover {
    opacity: 1;
  }

}

.receipt {
  //position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  &__close-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
    align-self: flex-end;
  }

  &__content {
    width: 69%;
    margin: 69px auto 45px auto;
  }

  &__field {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 49px;
  }

  &__field-label {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.7);
  }

  &__field-input {
    width: 100%;
    padding: 0 35px 0 0;
    font-size: 16px;

  }

  &__message-icon {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 0;
    bottom: 7px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__btns {
    display: flex;
    flex-direction: column;
  }

  &__btn {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    padding: 20px 0;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
  }

  &__btn--send {
    background-color: $orange-color;
    color: $white-color;
  }

  &__btn--print {
    color:  $orange-color;
  }

  &__error {
    color: red;
    position: absolute;
    bottom: -45px;
    font-size: 12px;
  }
}

@media (max-width: 510px) {
  .history-left__body-name__block{
    width: 55%;
  }

  .receipt__content {
    width: 75%;
  }
}

.gray-small {
  font-size: 12px;
  color: $gray-color;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  //max-width: 230px;
  //width: 70%;


}

.gray-small-time {
  font-size: 12px;
  color: $gray-color;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120px;

  @media (max-width: 660px) {
    display: none;
  }


}

.history__details {
  padding: 7px 0 7px 10px;
}

.history-accordion {
  //min-width: 554px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  @media (max-width: 500px) {
    display: flex;
    //flex-direction: column;
  }

  &__right {
    //margin-left: 125px;
    width: 32%;
  }

  &__left {
    //margin-left: 37px;
    width: 45%;
  }

  .col-right {
    margin-left: 120px;
  }

  &-col {
    margin-bottom: 20px;
  }

  &__title {
    font-size: 12px;
    color: $gray-color;

    padding-bottom: 4px;

    @media (max-width: 500px) {
      font-size: 10px;
    }
  }

  &__body {
    display: flex;
    align-items: center;
    font-size: 16px;

    @media (max-width: 500px) {
      font-size: 12px;
    }
  }
}

.bonus {
  color: $orange-color;
}

.check {
  //padding: 30px 0 0 0;
  display: flex;
  flex-direction: column;
  //max-width: 680px !important;
  //width:66%;
  //width:100%;

  &_result {
    color: #fff;
    padding: 20px 20px;
    text-align: center;
    font-size: 14px;
    background: $mainBackground;
    flex-grow: 1;
    &--error {
      display: flex;
      align-items: center;
    }
  }
  &_result-icon {
    margin-bottom: 10px;
  }
  &_result-desc {
    //font-family: $mB !important;
    font-size: 22px;
    margin-bottom: 10px;
  }
  &_result-link {
    font-family: $mM;
    font-size: 15px;
    background: rgba(255, 255, 255, 0.5);
    padding: 6px 10px;
    margin: 0 10px;
    color: #ff4200;
    border-radius: 5px;
    a {
      color: #4251ff;
    }
  }

  &_data-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 22px;
    font-family: $mM;


    &-title {
      color: rgba(136, 136, 136, 1);
      font-size: 14px;
    }
    &-body {
      color: #474747;
      @media (max-width: 680px) {
        padding: 0;
        //text-align: right;
      }
    }
  }
  &_send-mail {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    @media (max-width: 600px) {
      padding: 0;
    }

    &-input {
      margin-bottom: 10px;
      padding: 8px;
      border: 1px solid #888888;
      border-radius: 5px;
      width: 100%;
    }

    &-btn {
      align-items: center;
      padding: 10px 15px;
      border-radius: 5px;
      border: none;
      background: $mainBackground;
      color: #fff;
      font-size: 16px;
      font-family: $mB;
      margin-bottom: 20px;
    }
    &-validate-message {
      margin-bottom: 10px;
    }
  }
  &_footer-wrapper {
    padding: 0 10px;
    @media (max-width: 600px) {
      padding: 0;
    }
  }
  &_footer {
    margin-top: auto;
    padding: 20px 20px 0 10px;
    display: flex;
    box-shadow: 0px 3px 10px 1px rgb(34 60 80 / 20%);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    &-left-part {
      margin-right: 20px;
      display: flex;
      align-items: flex-end;
      img {
        height: 200px;
      }
    }
    &-info {
      margin-bottom: 10px;
      margin-top: 20px;
    }
    img {
      display: block;
    }
    &-info-title {
      font-family: $mB;
      font-size: 15px;
      margin-bottom: 10px;
      color: #7a727e;
    }
    &-info-sub-title {
      margin-bottom: 10px;
    }
    &-info-controller {
      display: flex;
      margin-bottom: 5px;
      @media (max-width: 460px) {
        padding: 0;
        flex-direction: column;
      }
    }

    input {
      padding: 5px;
      border-radius: 5px;
      border: 1px solid #888888;
      flex-grow: 1;
      margin-right: 10px;
      @media (max-width: 460px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
      }
    }

    .check_send-mail-validate-message--desktop {
      @media (max-width: 460px) {
        display: none;
      }
    }

    .check_send-mail-validate-message--mobile {
      @media (min-width: 460px) {
        display: none;
      }
    }

    button {
      background: $mainBackground;
      border: none;
      border-radius: 5px;
      padding: 10px;
      color: #fff;
    }

    &-info-store {
      display: flex;
      margin-top: 15px;
      img {
        border-radius: 5px;
        overflow: hidden;
      }
    }
    &-info-store-app {
      margin-right: 10px;

      img {
        width: 100px;
      }
    }

    &-info-store-google {
      img {
        width: 100px;
      }
    }

    @media (max-width: 680px) {
      flex-direction: column-reverse;
      text-align: center;
      padding-right: 10px;
      &-left-part {
        margin-right: 0;
        justify-content: center;
      }
      &-info-store {
        margin-top: 20px;
        justify-content: center;
      }
    }
  }
  &_print {
    max-width: 300px;
    padding-top: 20px;
    font-family: $mM;
    &-item {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &-title {
      padding-bottom: 5px;
      border-bottom: 1px solid #dbd8d8;
      margin-bottom: 20px;
      font-size: 12px;
      text-align: center;
      font-family: $mB;
    }
    &-body {
      text-align: center;
      font-size: 15px;
    }
    &-amount {
      font-size: 25px;
      font-family: $mM;
      &-currency {
        font-size: 20px;
      }
    }

    &-data-item {
      font-size: 15px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &-data-item-title {
      width: 220px;
    }
    &-data-item-body {
      width: 220px;
      text-align: right;
    }
    &-payment-method {
      margin-top: 25px;
      font-size: 15px;
      position: relative;
      display: inline-block;
      &-icon {
        position: absolute;
        top: 2px;
        right: -62px;
      }
    }
  }
}

.history-left__body {
  .css-15v22id-MuiAccordionDetails-root {
    padding: 8px 0 10px 0
  }
}

//Mui-expanded
.css-1udso76-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin-top: 0;
  border-radius: 15px;
  //box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  box-shadow: 0px 4px 15px -3px rgba(0, 0, 0, 0.05) !important;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.1)) !important;
}

.css-1udso76-MuiPaper-root-MuiAccordion-root.Mui-expanded:first-of-type {
  margin-top: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.modal-history {
  .modal__content {
    max-width: 500px;
    width: 100%;
  }
}

.media-history-skeleton {
  display: none;
}

.media-less-history-skeleton {
  display: none;
}

.media-less450-history-skeleton {
  display: none;
}

@media (max-width: 768px) {
  .modal-history {
    .modal__content {
      //max-width: 400px;
      width: 90% !important;
    }
  }
}

@media (max-width: 450px) {
  .modal-history {
    .modal__content {
      //max-width: 300px;
      width: 95% !important;
    }
  }


  .history {
    .receipt-modal__form {
      margin: 50px auto 0 auto;
    }

    .receipt-modal {
      min-height: 300px;
    }
  }
}

@media (max-width: 900px) {
  .history-left__body {
    #panel1a-header {
      padding: 0;
    }
  }

  .history-skeleton {
    display: none;
  }

  .media-history-skeleton {
    display: flex;
  }

  .media-less-history-skeleton {
    display: none;
  }
  .media-less450-history-skeleton {
    display: none;
  }

  //.history {
  //  border-radius: 25px 25px 0 0;
  //  margin-top: -22px;
  //  background: white;
  //}

  .history-accordion {
    justify-content: space-between;
  }
  .history-control__buttons {
    width: 100%;
  }

}

@media (max-width: 660px) {
  .media-less-history-skeleton {
    display: flex;
  }
  .media-history-skeleton {
    display: none;
  }
}

@media (max-width: 535px) {
  .history-accordion__body {
    font-size: 14px;
  }
  .history-accordion__left {
    width: 50%;
  }
  .history__details {
    padding: 7px 0 7px 10px;
  }
}

@media (max-width: 470px) {

  .history-accordion__body {
    font-size: 12px;
  }

  .media-less-history-skeleton {
    display: none;
  }
  .media-less450-history-skeleton {
    display: flex;
  }
}

@media (max-width: 430px) {
  .history-accordion__body {
    font-size: 10px;
  }
}

@media (max-width: 375px) {
  .history-accordion {
    display: flex;
    flex-direction: column;
  }

  .receipt__content {
    margin: 40px auto;
  }
}

@media (max-width: 340px) {
   .history-left__body-name {
     width: 120px;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
   }
}

//@media (max-width: 700px) {
//
//
//    .receipt-modal__form {
//      margin: 30px auto 0 auto;
//    }
//
//    .receipt-modal__footer{
//
//      .receipt-modal__send-btn {
//        background: #EF5C27;
//        border-radius: 10px;
//        height: 50px;
//        font-size: 12px;
//        line-height: 19px;
//        color: #FFFFFF;
//        margin-bottom: 30px;
//        padding: 10px 0;
//
//      }
//    }
//
//}
