@import "../../variables/index";

.custom-calendar {
  &__calendar {
    width: 330px;
    border-radius: 15px;
    border: 1px solid $white-color-border;
    border: none;

    &-wrap {
      flex: none;
      width: 40px;
      padding: 10px !important;
    }

    &-head {
      display: flex;
      justify-content: space-between;
      //padding: 20px;

      p{
        display: flex;
        align-self: center;
      }

      img {
        cursor: pointer;
        width: 18px;
        height: 18px;
      }
    }

    &-selected-date {
      width: 90%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      padding: 20px 0 15px;
      border-bottom: 1px solid $white-color-border;
      cursor: pointer;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $orange-color;
    }

    &-button {
      display: flex;
      padding: 20px 0;
      justify-content: center;
    }
  }

  .react-calendar {
    border: none;
    .react-calendar__month-view__weekdays__weekday {
      color: $orange-color;
    }
    .react-calendar__tile--active {
      border-radius: 50%;
      background-color: $orange-color;
    }
    .react-calendar__tile--now{
      border-radius: 50%;
      background-color: $orange-color;
      border: 1px solid $black-color-b;
    }
  }

}




