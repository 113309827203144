@import "../../variables/index";

.main-fav {
  display: flex;
  max-width: $width;
  margin: 0 auto;
  justify-content: space-between;
  padding: 0 20px 50px 20px;
  width: 100%;
}

.favorites {
  // width: 66%;
  // display: flex;
  // flex-direction: column;

  &-right {
    width: 32%;
  }

  &__favorite-service {
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    color: $black-color;
    margin: 25px 0 30px;
  }

  &__add-main {
    display: flex;
    width: 100%;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    &-block {
      width: 30%;
      display: flex;
      flex-direction: column;
      padding: 15px 0;
      margin: 0 auto;
    }

    &-button {
      border: 1px solid $orange-color;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      display: flex;
      align-self: center;
      margin-bottom: 10px;
      cursor: pointer;

      &-plus {
        margin-left: 25px;
      }
    }

    &-text {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: $black-color;
      text-align: center;
    }
  }

  &__add-templates {
    //border-top: 1px solid $white-color-border;
    //border-bottom: 1px solid $white-color-border;
    width: 100%;

    &-wrap {
      display: flex;
      padding: 0 0 15px;
      width: 38%;
      justify-content: space-between;

      &-text {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: $black-color;

      }

      &-plus {
        display: flex;
        cursor: pointer;
        margin-left: 10px;
      }

      &-add {
        margin-left: 7px;
        align-self: center;
        display: flex;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: $orange-color;
        width: 150px;
      }

      &-empty {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        align-items: center;
        display: flex;
        color: $gray-color;
        padding-top: 15px;
      }
    }
  }
}

.add-favorites {
  width: $width;
  margin: 0 auto;
  padding-bottom: 65px;
  flex-grow: 1;

  &__select-category {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $gray-color;
    padding-bottom: 30px;
  }

  &__catalog {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 30px;

    &-header {
      padding-bottom: 30px;
    }

    &-wrap {
      display: flex;
      width: 30%;
      padding: 6px 0;
      height: 70px;

      &-check {
        display: flex;
        align-self: center;
      }

      &-img {
        height: 45px;
        width: 45px;
        border-radius: 50%;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        margin-top: 6px;

        &-pic {
          height: 25px;
          width: 25px;
        }
      }

      &-text {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $black-color;
        display: flex;
        align-self: center;
        padding-left: 10px;
      }
    }
  }

  &__button-main {
    text-decoration: none;

    button:disabled,
    button[disabled]{
      //border: 1px solid #999999;
      background-color: #cccccc;
      color: #666666;
      cursor: not-allowed;
    }
  }
}
.accordion__wrap-text-left {
  width: 70%;
  padding: 0 5px 0 0;
}
.accordion__wrap-comment {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 30%;
  padding: 0 5px;
}
.accordion-menu {
  display: flex;
  width: 67%;
  flex-direction: column;

  &__body {
    border: 1px solid $red-color;
    display: flex;
  }
}

.selected-favorites {
  width: $width;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 50px;

  &__left-main {
    width: 753px;
  }

  &__right-main {
    width: 367px;
  }

  &__header {
    padding-top: 25px;
    display: flex;

    &-text {
      display: flex;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: $black-color;
      margin: 0 auto;
      padding-top: 10px;
      text-decoration: none;
    }

    &-fav {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      padding: 30px 0 40px;
      border-bottom: 1px solid $white-color-border;

      &-item {
        width: 30%;
        display: flex;
        flex-direction: column;
        text-decoration: none;
        cursor: pointer;

        &-img2 {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          background-color: $yellow-white-color;
          display: flex;
          margin: 0 auto;
        }

        &-img3 {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          border: 1px solid $orange-color;
          display: flex;
          margin: 0 auto;
          cursor: pointer;
        }


        &-img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          background-color: $background-orange-color-o;
          display: flex;
          margin: 0 auto;
          cursor: pointer;

          &-image {
            display: flex;
            width: 22px;
            height: 30px;
            margin: 0 auto;
            align-self: center;
          }
        }
      }
    }
  }

  &__accordion {
    padding-top: 40px;

    &-img {
      padding-right: 7px;
      display: flex;
      align-self: center;
    }

    &-text {
      display: flex;
      align-self: center;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: $orange-color;
    }

    &-head {
      display: flex;
      padding-bottom: 15px;

      &-text {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: $black-color;
        padding-right: 25px;
      }

      &-add {
        display: flex;
        cursor: pointer;
      }
    }
  }
}
.css-l0jafl {
  width: 90% !important;
}
.css-o4b71y-MuiAccordionSummary-content {
  width: 90% !important;
}
.accordion {
  display: flex;
  width: 100%;
  align-items:center;
  justify-content: space-between;
  padding: 0 2px 0 2px;
  &__wrap-img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    flex-shrink: 0;
    align-self: center;

    &-image {
      height: 100%;
      width: 100%;
      display: flex;
      align-self: center;
      margin: 0 auto;
      object-fit: contain;
    }
  }

  &__wrap-text {
    align-self: center;
    width: 45%;
    max-width: 560px;
    overflow: hidden;
    margin-right: 15px;
    display: flex;
    justify-content: space-between;

    &-num {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $black-color;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
    }

    &-mob {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: $gray-color;
    }
  }

  &__footer-left-comment {
    margin-bottom: 10px;
  }

  &__wrap-price {
    display: flex;
    align-self: center;
    width: 15%;
  }

  &__wrap-dots {
    display: flex;
    align-self: center;
    width: 6%;
    padding-right: 5px;
    height: 45px;
    margin-left: 20px;
    margin-right: 5px;
    align-items: center;
    justify-content: center;

    img {
      height: 20px;
      width: 20px;
    }
  }

  &__footer {
    display: flex;

    &-left {
      width: 100%;
      display: flex;
      &-wrap {
        width: 50%;
      }

      &-mob {
        padding-bottom: 6px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: $gray-color;
        word-wrap: break-word;

        &-wrap {
          width: 40%;
        }
      }

      &-visa {
        display: flex;

        &-img {
          display: flex;
          align-self: center;
          height: 13px;
          padding-right: 7px;
        }

        &-number {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: $black-color;
          align-self: center;
          padding-right: 11px;
        }
      }

      &-num {
        display: flex;
        padding-bottom: 20px;

        &-item {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: $black-color;
          white-space: nowrap;
          overflow: hidden;
          padding: 5px;
          text-overflow: ellipsis;

          &-span {
            margin-top: 5px;
          }
        }
      }
    }
  }
}

.modal-service-code {
  height: 200px;
  padding: 12px;
  display: flex;
  flex-direction: column;

  input {
    width: 100% !important;
  }

  .custom-button {
    min-width: 100% !important;
    padding: 14px !important;
    text-align: center !important;
  }


  &__delete-body-text {
    display: none;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #474747;
  }

  &__delete-wrap{
    width: 100%;
    height: 130px;
    flex-direction: column;

    &-close {
      cursor: pointer;
      display: flex;
      align-self: center;
      padding-left: 15px;

      img {
        width: 18px;
        height: 18px;
      }
    }

    &-foot {
      display: flex;
      justify-content: space-evenly;
      margin: auto;
      padding-top: 50px;
      width: 100%;
    }

    &-cancel {
      display: flex;
      align-items: center;
      align-self: center;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #EF5C27;
      border-bottom: 1px dotted #EF5C27;
      cursor: pointer;
      margin-right: 30px;
    }

    &-head {
      display: flex;
      justify-content: space-between;

      &-text {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #474747;
      }
    }
  }

&__close {
display: flex;
justify-content: end;
margin-bottom: 25px;

  img {
    cursor: pointer;
  }
}

  &__btn {
  margin: 30px 0;
  }
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 6px 0 !important;
  border-radius: 15px !important;
}

.summary {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  position: relative !important;
}


.expandIcon {
  transform: rotate(180deg);
}

.css-1udso76-MuiPaper-root-MuiAccordion-root.Mui-expanded:first-of-type {
  margin-bottom: 20px !important;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  width: 100%;
}

.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  width: 100%;
}

.css-1udso76-MuiPaper-root-MuiAccordion-root {
  width: 100%;
}

.delete-fav-modal >div >div {
  width: 27%;
}

.selected-favorites__accordion-body {

  .css-17o5nyn {
    width: 100%;
  }

  .css-1n3tkec.Mui-expanded {
    width: 100% !important;;
    border-radius: 15px !important;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px !important;
  }

  .css-15v22id-MuiAccordionDetails-root {
    padding: 8px 10px 10px;
  }
  .accordion {
    padding: 0;
  }

  .accordion__wrap-dots {
    align-self: center;
    width: 6%;
    padding-right: 5px;
    height: 34px;
    margin-left: 20px;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 1020px) {
  .selected-favorites__accordion-body {
    .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root {
      padding: 0;
    }
  }
}

.favorites-skeleton {
  display: flex;
}
.media-favorites-skeleton {
  display: none;
}
.media610-favorites-skeleton {
  display: none;
}
.media410-favorites-skeleton {
  display: none;
}

.accordion__debt {
  width: 15%;
  padding: 10px;
  color: red;
}

.selected-favorites__accordion-body .css-idmiha-MuiPaper-root-MuiAccordion-root::before {
  right: 0px;
}

@media (max-width: 950px) {
  .delete-fav-modal > div > div {
    width: 30%;
  }

  .modal-service-code__delete-wrap {
    height: 110px;
  }

  .modal-service-code__delete-wrap-close {
    display: flex;
    align-self: center;

    img {
      height: 14px;
      width: 14px;
    }
  }

  .modal-service-code__delete-wrap-foot {
    padding-top: 40px;
    width: 100%;
  }

  .modal-service-code__delete-wrap-cancel {
    font-size: 14px;
  }

  .modal-service-code__delete-wrap-btn {
    .custom-button {
      color: rgb(255, 255, 255) !important;
      background: rgb(239, 92, 39) !important;
      min-width: 107px !important;
      padding: 10px 30px !important;
      border-radius: 15px !important;
      font-size: 12px;
    }
  }

  .modal-service-code-head-text {
    font-size: 16px;
  }
}

@media (max-width: 900px) {
  .favorites-skeleton {
    display: none;
  }
  .media-favorites-skeleton {
    display: flex;
  }
  .media610-favorites-skeleton {
    display: none;
  }
  .media410-favorites-skeleton {
    display: none;
  }
}


@media (max-width: 768px) {
  .favorites {
    width: 100%;
  }

  .favorites-right {
    display: none;
  }
}

@media (max-width: 700px) {
  .delete-fav-modal >div >div {
    width: 40%;
  }
}

@media (max-width: 610px) {
  .selected-favorites__accordion-body {
    .css-yw020d-MuiAccordionSummary-expandIconWrapper {
      display: none !important;
    }
  }
  .accordion__wrap-text {
    max-width: 200px;
  }
  .accordion__wrap-dots {
    margin-right: 0px;
  }
  .selected-favorites__accordion-body .css-idmiha-MuiPaper-root-MuiAccordion-root::before {
    right: 0px !important;
  }
  .favorites-skeleton {
    display: none;
  }
  .media-favorites-skeleton {
    display: none;
  }
  .media610-favorites-skeleton {
    display: flex;
  }
  .media410-favorites-skeleton {
    display: none;
  }
}

@media (max-width: 550px) {
  .selected-favorites__accordion-body {
    .css-1n3tkec {
      width: 100% !important;
    }
    .css-17o5nyn {
      width: 95% !important;
    }
    .css-1fx8m19 {
      width: 5% !important;
    }
  }
}

@media (max-width: 520px) {
  .accordion__wrap-img {
    width: 38px;
    height: 38px;
  }

  .delete-fav-modal >div >div {
    width: 50%;
  }
}

@media (max-width: 510px) {
  .accordion__wrap-button {
    .custom-button {
      font-size: 12px;
      padding: 5px 10px !important;
      width: 110px;
      border-radius: 10px !important;
    }
  }

  .accordion__debt {
    font-size: 14px;
  }

  .accordion__wrap-dots {
    display: inline-block;
    align-self: center;
    width: 6%;
    padding-right: 5px;
    height: 45px !important;
    margin-left: 20px;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .selected-favorites__accordion-body {
    .css-1fx8m19 {
      display: none!important;
    }
    .css-1iji0d4 {
      padding: 0;
    }
  }
}

@media (max-width: 450px) {
  .favorites__add-templates {
    margin-top: 15px;
  }
}

@media (max-width: 440px) {
  .accordion__wrap-text-num {
    font-size: 12px;
  }

  .accordion__wrap-dots {
    display: inline-block;
    align-self: center;
    width: 6%;
    padding-right: 5px;
    height: 37px !important;
    margin-left: 20px;
    align-items: center;
    justify-content: center;
  }


  .modal-service-code__delete-wrap-head {
    justify-content: flex-end;
  }

  .modal-service-code__delete-wrap-head-text {
    display: none;
  }

  .modal-service-code__delete-body-text {
    display: block;
    text-align: center;
    width: 100%;
    font-size: 18px;
  }

  .modal-service-code__delete-wrap-foot {
    padding-top: 25px;
  }

  .favorites__add-templates-wrap-text {
    font-size: 16px;
  }

  .favorites__add-templates-wrap-add {
    font-size: 12px;
  }

  .accordion__wrap-button {
    .custom-button {
      font-size: 10px;
      padding: 5px !important;
      height: 35px;
      align-self: center;
      border-radius: 10px !important;
    }
  }

  .accordion__footer-left {
    width: 100%;
  }

  .accordion__footer-left-mob-wrap {
    width: 45% !important;
    //margin: 0 auto !important;
  }

  .accordion__footer-left-num-item {
    font-size: 12px;
  }
  .accordion__footer-left-num-item-span {
    font-size: 12px;
  }
  .accordion__debt {
    font-size: 12px;
    margin: 10px 8px;
  }
  .accordion__wrap-button .custom-button {
    width: 80px;
  }
}

@media (max-width: 415px) {
  .delete-fav-modal > div > div {
    width: 60%;
  }
  .media410-favorites-skeleton {
    display: flex;
  }
  .media610-favorites-skeleton {
    display: none;
  }
  .media-favorites-skeleton {
    display: none;
  }
  .accordion__footer-left {
    display: flex;
    justify-content: space-between;
  }

  .modal-service-code__delete-wrap-cancel {
    font-size: 12px;
  }

  .modal-service-code__delete-wrap-head-text {
    font-size: 14px;
  }

  .selected-favorites__accordion-body {
    .css-1fx8m19 {
      display: none!important;
    }
    .css-1iji0d4 {
      padding: 0;
    }
  }
}

@media (max-width: 400px) {
  .accordion__wrap-text-num {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (max-width: 385px) {
  .accordion__wrap-dots {
    display: inline-block;
    align-self: center;
    width: 6%;
    padding-right: 5px;
    height: 38px !important;
    margin-left: 20px;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 374px) {
  .delete-fav-modal >div >div {
    width: 60%;
  }
}

