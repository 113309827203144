@import "../../variables/index";

.header {
  max-width: $width;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-buttons {
    display: flex;
    .basket-mobile, .bonuses-mobile {
      display: none;
    }
    .header-right-basket {
      border: none;
    }
  }
  &-right {
    display: flex;
    align-items: center;
    position: relative;
    &-count {
      position: absolute;
      height: 25px;
      width: 25px;
      top: 7px;
      right: 255px;
      border-radius: 50%;
      background: white;
      color: black;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-basket {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 67px;
      height: 67px;
      cursor: pointer;
      border-radius: 50%;
      border: 1px solid white;
      &-image {
        display: flex;
        width: 30px;
        height: 30px;
      }
    }
    @media (max-width: 900px) {
      display: none;
    }
  }
  &-wrapper {
    background: radial-gradient(56.22% 143.13% at -5.99% 106.25%, #F99B29 0%, rgba(239, 92, 39, 0) 100%), radial-gradient(137.1% 349.03% at 111.06% -5.15%, #ef5c27 0%, rgba(239, 92, 39, 0) 100%), #EF5C27;
  }

  .auth-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: $auth-color;
  }
  &__auth {
    font-size: 14px;
    padding: 15px;
    &-wrapper {
      display: flex;
      align-items: center;
      margin-left: 15px;
      margin-right: 73px;
    }
    &-setting {
      display: flex;
      align-items: center;
      &__title {
        color: $white-color;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        align-items: center;
      }
    }
    &-name {
      font-size: 18px;
      color: $white-color;
    }
  }
}

.header-wrapper-head {
  .modal__content.active {
    width: 420px;
  }
}

.auth-icon-image {
  width: 67px;
  height: 67px;
  border-radius: 50%;
  object-fit: cover;
}

.logo {
  margin: 10px 0 10px 40px;
  width: 122px;
  height: 60px;
  object-fit: contain;
}

.hamburger-menu{
  position: absolute;
  top: 26px;
  width: 27px;
  height: 23px;
}

.menu__btn {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: $white-color;
  transition-duration: .25s;
  cursor: pointer;
}

.menu__btn > span::before {
  content: '';
  top: 7.5px;
}

.menu__btn > span::after {
  content: '';
  top: 15.5px;
  width: 11px;

}

.menu__box {
  position: fixed;
  top: 0;
  left: -350px;
  width: 350px;
  height: 100%;
  margin: 0;
  padding: 15px 15px 15px 10px;
  list-style: none;
  background-color: $white-color;
  box-shadow: 2px 2px 6px $menu-box-color;
  transition: left 0.2s;
  z-index: 2;

  .language {
    color: $orange-color;
    border: none;
    outline: none;
    text-align: right !important;
  }

  li {
    display: flex;
    align-items: center;
    padding: 10px 0;
    justify-content: space-between;

    a {
      font-size: 14px;
    }

  }


  &--open{
    left: 0;
  }

}

.menu__item {
  display: flex;
  align-items: center;
  color: $black-color;
  text-decoration: none;
  transition-duration: .25s;
  font-size: 14px;
  cursor: pointer;

  img {
    padding-right: 10px;
  }
}

.dividing-line {
  border-bottom: 1px solid $dividing-color;

  .login-register {
    margin-left: 15px;
  }

}

.header-logo {
  width: 100%;
  text-decoration: none;
}

.logoWrap {
  display: flex;
  border: 2px solid;
}

.online-text {
  display: flex;
  width: 150px;
  padding: 5px 5px 5px 10px;
  font-size: 14px;
  color: #FFFFFF;
  text-decoration: none;
  align-self: center;
}

.logoWrap-image {
  display: flex;
  text-decoration: none;
}

.online-text-center {
  display: none;
}

.lang-select {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 45px;

  .option {
    text-decoration: none;
    padding: 5px;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
  }

  .selected {
    background-color: #EF5C27;
    color: #FFFFFF
  }
}

.basket-button {
  position: relative;
  .header-right-count {
    top: 0px;
    right: 12px;
  }
  .header-right-count.auth-count {
    right: 5px;
  }
}

.header-buttons .basket-button .header-right-count {
  top: 12px;
  height: 14px;
  width: 14px;
  font-size: 10px;
}

.header-buttons .basket-button .header-right-count.auth-count {
  right: 22px;
}

.header-buttons .basket-button .header-right-count.notauth-count {
  right: 32px;
}

@media (max-width: 5060px) {
  .logoCenter {
    display: none;
  }

  .logo {
    display: flex;
  }
}

@media (max-width: 1900px) {
  .logoCenter {
    display: none;
  }

  .logo {
    display: flex;
  }
}

@media (max-width: 900px) {
  .header__auth-setting {
    justify-content: center;
  }

  .logoWrap-image {
    display: none;
  }

  .logoWrapCenter {
    display: flex;
    flex-direction: column;
    text-decoration: none;
  }

  .online-text-center {
    display: flex;
    padding: 5px 5px 5px 10px;
    font-size: 14px;
    color: #FFFFFF;
    text-decoration: none;
    align-self: center;
  }

  .hamburger-menu {
    margin-left: 10px;
  }

  .header-wrapper-head {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .logoCenter {
    display: flex;
    margin: 0 auto;
    padding-top: 8px;
  }

  .logo {
    display: none;
  }

  .header-buttons .basket-mobile {
    position: absolute;
    display: flex;
    left: 35px;
  }

  .header-buttons .bonuses-mobile {
    position: absolute;
    display: flex;
    top: 18px;
    right: 20px;
    height: 27px;
    a {
      display: flex;
      text-decoration: none;
    }
    span {
      margin: auto 6px auto auto;
      color:#FFFFFF;
    }
    img {
      display: flex;
    }
  }
}

@media (max-width: 400px) {
  .menu__box {
    width: 300px;
    li {
      a {
        font-size: 12px;
      }
    }
    .language {
      font-size: 12px;
    }
  }
}

@media (max-width: 350px) {
  .menu__box {
    width: 275px;
    li {
      a {
        font-size: 12px;
      }
    }
    .language {
      font-size: 12px;
    }
  }
}

@media (max-width: 330px) {
  .logoCenter {
    display: flex;
    margin: 0 auto;
  }
}
