@import "../../../variables/index";

.recurring-period {
  display: flex;
  flex-direction: column;

  &__title {
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    color: $black-color;
    margin-bottom: 30px;
  }

  &__period-box {
    display: flex;
    flex-direction: column;
  }

  &__period {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  &__img {
    margin-right: 15px;
  }



  &__period-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #474747;
    margin-right: 30px;
    display: flex;
    gap: 30px;
  }

  &__near-date{
    display: flex;
    align-items: center;
    padding: 10px 15px;
    max-width: 250px;
    height: 35px;
    background: #FFF6E4;
    border-radius: 15px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #474747;
    margin-bottom: 30px;
    margin-top: 20px;
  }

  &__btns {
    display: flex;
    gap: 30px;
  }

  &__week-days {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    width: fit-content;
  }

  &__custom-select {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__select-header {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__select-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #EF5C27;
    margin-right: 10px;
  }

  &__option-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    position: absolute;
    top: 20px;
    left: -10px;
    z-index: 10;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    max-height: 300px;
    min-width: 100px;
  }

  &__option-content {
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__option {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #474747;
    cursor: pointer;
    margin: 12px 0;

    &:hover {
      color: #EF5C27;
    }
  }
}